.footer {
  width: 100%;
  padding: 5rem 0;
  background: rgb(10, 10, 10);
  position: relative;
  z-index: 10;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  position: relative;
}

.social-icons {
  display: flex;
  gap: 1rem; /* Adds a gap of 1rem between icons */
}

.social-icons .icon {
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icons .icon:hover {
  color: #f9db1f;
  transform: scale(1.1);
}

/* Footer sections styling */
.footer-section {
  text-align: left;
  padding: 0 1rem;
}

.footer-section .address {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.footer-section .address:hover {
  transform: scale(1.02);
}

.footer-section img {
  width: 180px;
  height: 180px;
}

.footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #f9db1f;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.8rem;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
  cursor: pointer;
}

.footer-section ul li a:hover {
  color: #f9db1f;
}

.footer-container p {
  margin-top: 2rem;
  font-size: 1rem;
  text-align: center;
  color: #fff;
}

.footer.rtl {
  direction: rtl;
  text-align: right;
}

.footer.rtl .footer-section {
  text-align: right;
}

.footer.rtl ul {
  padding-right: 1em;
}

@media screen and (max-width: 640px) {
  .footer-branding {
    flex-direction: column;
    text-align: center;
  }
}
