@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}
body {
  background-color: #171717;
}
h1,
h4,
p,
a {
  color: #fff;
  text-decoration: none;
}
ul {
  list-style-type: none;
}

.btn {
  padding: 8px 28px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #f9db1f;
  color: #222;
  border: 1px solid;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  padding: 8px 28px;
  font-size: 1rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}
.btn-light:hover {
  background: transparent;
  color: #f9db1f;
  transition: 0.3s;
  border: 1px solid #f9db1f;
}

.line {
  height: 8px;
  background: #3c3d37;
  justify-content: center;
  position: relative;
}

.top {
  position: relative;
  z-index: 1;
}
