.navbar-class {
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  transition: background-color 0.3s ease;
  background-color: transparent;
  z-index: 1000;
}

.navbar-class.scrolled {
  background-color: rgb(0, 0, 0, 0.8);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.menu {
  display: flex;
  align-items: center;
}

.menu-items {
  display: flex;
  align-items: center; /* Align items vertically in the middle */
}

#btn-navbar {
  border-radius: 5px;
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.topButton {
  color: white;
}

@media screen and (max-width: 600px) {
  .menu-items {
    gap: 0rem; /* Reduce spacing between items */
  }

  #btn-navbar {
    margin: 0 -0.8rem; /* Reduce margin around buttons */
  }

  .menu-text {
    display: none; /* Hide text for smaller screens */
  }
}
