.language-selector {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
}

.flag-icon {
  width: 30px;
  height: 20px;
  border-radius: 4px;
}

._dropdown-menu {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  top: 100%;
  left: -5px;
  max-width: 50px;
  border-radius: 4px;
  overflow: hidden;
}

._dropdown-item {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.dropdown-item:hover {
  background: #f0f0f0;
}

.dropdown-item img {
  width: 30px;
  height: 20px;
}
